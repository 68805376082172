import React from 'react'
import useTopics from '../../../lib/useTopics'
import Icon from '../../common/Icon'
import LinkPill from '../../common/LinkPill'
import PaginatedWithButton from '../../common/pagination/PaginatedWithButton'
import TopicPill from '../../topics/TopicPill'

const GroupFilters = ({ selectedFilters, setSelectedFilters, group }) => {
  const {
    items,
    isLoadingInitialData,
    isLoadingMore,
    isReachingEnd,
    loadMore,
  } = useTopics({ group_id: group?.id, items: 10 })

  return (
    <div className="flex flex-row gap-2 mb-2">
      <LinkPill
        active={Object.keys(selectedFilters).length === 0}
        onClick={() => setSelectedFilters({})}
        className="px-4 max-w-min"
      >
        All
      </LinkPill>
      <LinkPill
        active={selectedFilters.is_pinned}
        onClick={() => setSelectedFilters({ is_pinned: true })}
        LeftComponent={
          <Icon
            name="pin"
            color={selectedFilters.is_pinned ? 'white' : 'topic-pink'}
          />
        }
        className={`max-w-min ${selectedFilters.is_pinned ? 'text-white' : 'text-topic-pink'}`}
      >
        Pinned
      </LinkPill>
      <div className="horizontal-scroller scrollbar-hide flex rounded-full">
        <PaginatedWithButton
          isLoadingMore={isLoadingMore}
          isLoadingInitialData={isLoadingInitialData}
          isReachingEnd={isReachingEnd}
          loadMore={loadMore}
          EmptyComponent={<></>}
          buttonClassName="!px-4 !py-0"
        >
          {items?.map((topic) => (
            <div key={`topic-${topic?.name}`} className="mr-2 max-w-full">
              <TopicPill
                name={topic?.name}
                color={topic?.color}
                alt={false}
                onClick={() => setSelectedFilters({ topic_name: topic?.name })}
                active={selectedFilters.topic_name === topic?.name}
              />
            </div>
          ))}
        </PaginatedWithButton>
      </div>
    </div>
  )
}

export default GroupFilters
