import { formatDistanceToNowStrict } from 'date-fns'
import Link from 'next/link'
import useMemoizedPathFor from '../../lib/pathFor'
import useUser from '../../lib/useUser'
import Breadcrumb from '../common/Breadcrumb'
import CLAvatar from '../common/CLAvatar'
import Container from '../common/Container'
import Icon from '../common/Icon'
import LinkPill from '../common/LinkPill'
import ProBadge from '../common/ProBadge'
import ReplyForm from '../forms/ReplyForm'
import AwardStampRow from '../stamps/AwardStampRow'
import TopicsRow from '../topics/TopicsRow'
import ActivityContent from './ActivityContent/ActivityContent'
import PostActions from './PostActions'
import RenderResource from './RenderResource'
import Replies from './Replies'
import ReplyCTA from './ReplyCTA'
import UpvoteButton from './UpvoteButton'

const Post = ({
  post,
  postInId,
  dontShowAll,
  hideAvatar,
  archived = false,
  referrer,
  collapsable = true,
}) => {
  const { user } = useUser()
  const postableHref = useMemoizedPathFor(post?.postable_type, post?.postable)
  const userHref = useMemoizedPathFor('user', post?.user)
  const postHref = useMemoizedPathFor('post', post)

  return (
    <Container noBottomPadding>
      <div className="py-2 px-4 flex flex-col w-full justify-start bg-white rounded-none md:rounded-lg mb-4">
        {referrer && <Breadcrumb referrer={referrer} />}
        <div className="flex flex-row items-start align-middle pt-3 px-0 lg:px-2">
          <div className="hidden lg:block mr-1 lg:mr-6">
            <UpvoteButton
              hasVoted={post.user_has_upvoted}
              type="Post"
              id={post.id}
              itemSlug={post.slug}
              count={post.upvote_count}
              item={post}
            />
          </div>
          {!hideAvatar && (
            <div className="relative top-0 left-0">
              <CLAvatar
                key={`${post?.postable?.name}-post-postable-avatar`}
                slug={post?.postable?.slug}
                avatar={post?.postable?.avatar}
                name={post?.postable?.name}
                type={post?.postable_type}
                className="w-max flex border border-divider"
              />
              <div className="absolute bottom-0 -right-6">
                <CLAvatar
                  key={`${post?.user?.name}-post-user-avatar`}
                  slug={post?.user?.slug}
                  avatar={post?.user?.avatar}
                  name={post?.user?.name}
                />
              </div>
            </div>
          )}

          <div className="mx-auto flex flex-col ml-10 w-full">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-start">
                <Link href={postableHref}>
                  <p className="flex-shrink overflow-hidden text-black text-sm font-bold break-words line-clamp-3 cursor-pointer">
                    {post?.postable?.name}
                  </p>
                </Link>
                {post.postable?.visibility === 'members' && (
                  <Icon
                    name="lock"
                    color="offBlack"
                    size={16}
                    className="ml-2 mt-0.5"
                  />
                )}
              </div>
              {!archived && <PostActions item={post} groupId={postInId} />}
            </div>

            <div className="w-full flex flex-row justify-between">
              <div className="flex flex-row items-center">
                <Link
                  href={userHref}
                  className="flex-grow text-disabled text-tiny line-clamp-2 mr-1"
                >
                  {post?.user?.name}
                </Link>
                <ProBadge
                  isPro={post?.user?.is_pro}
                  className="flex-shrink-0"
                />
              </div>
              <Link
                href={postHref}
                className="flex-shrink-0 text-disabled text-tiny"
              >
                {formatDistanceToNowStrict(new Date(post?.created_at), {
                  addSuffix: true,
                })}
              </Link>
            </div>
          </div>
        </div>
        <div className="pl-0 lg:ml-20 mt-4">
          <div className="flex flex-col">
            <div className="flex flex-row flex-grow mb-4 lg:px-0 items-center">
              <div className="block lg:hidden mt-1.5 mr-2 lg:mt-0 lg:mr-0">
                <UpvoteButton
                  hasVoted={post.user_has_upvoted}
                  type="Post"
                  id={post.id}
                  itemSlug={post.slug}
                  count={post.upvote_count}
                  item={post}
                />
              </div>
              <h2 className="text-black text-lg md:text-xl font-bold line-clamp-3">
                {post?.title}
              </h2>
            </div>
            <div className="w-full lg:px-0">
              <ActivityContent item={post} collapsable={collapsable} />
              {post?.is_edited && <span className="text-subtitle">Edited</span>}
              <TopicsRow
                topics={post?.topics}
                alt
                PrefixComponents={
                  post?.is_pinned
                    ? [
                        <LinkPill
                          alt
                          LeftComponent={<Icon name="pin" color="topic-pink" />}
                          className="text-topic-pink"
                        >
                          Pinned
                        </LinkPill>,
                      ]
                    : null
                }
                containerStyle="mt-4"
              />
              <AwardStampRow
                itemStamps={post?.stamps}
                stampableId={post?.id}
                stampableType="Post"
                canAwardStamp={post?.user.id !== user?.id}
                item={post}
              />
            </div>
            <div className="-mx-4 md:-mx-0">
              <RenderResource item={post} />
            </div>
          </div>
        </div>
        <hr className="my-4 -mx-4" />
        {post?.reply_count !== 0 ? (
          <Replies
            count={post?.reply_count}
            replies={post?.replies}
            parentId={post?.id}
            parentSlug={post?.slug}
            parentType="Post"
            groupId={postInId}
            dontShowAll={dontShowAll}
            disableReplyActions={archived}
          />
        ) : (
          <ReplyCTA itemArchived={archived} />
        )}
        {!archived && (
          <ReplyForm
            itemId={post?.slug}
            itemType="Post"
            item={post}
            videoEnabled={post?.postable?.video_enabled || user?.video_enabled}
          />
        )}
      </div>
    </Container>
  )
}

export default Post
