import React from 'react'
import LinkPill from '../../common/LinkPill'

const EventFilters = ({ selectedFilters, setSelectedFilters }) => (
  <div className="w-full mx-auto flex flex-row justify-start items-start gap-2 mb-4">
    <LinkPill
      active={selectedFilters.upcoming}
      onClick={() => setSelectedFilters({ upcoming: true })}
      className="px-8 max-w-min"
    >
      Upcoming
    </LinkPill>
    <LinkPill
      active={selectedFilters.past}
      onClick={() => setSelectedFilters({ past: true })}
      className="px-8 max-w-min"
    >
      Past
    </LinkPill>
  </div>
)

export default EventFilters
